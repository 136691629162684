/* Leaderboard & Stats CSS  */

.leaderboard {
  width: 600px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid #fc03a1;
  background-color: rgba(0, 0, 0, .3);
  color: #fc03a1;
  text-align: center;
  /* overflow: scroll; */
  font-size: 18px;
  line-height: 1.7;
  text-shadow: 0 0 15px #fc03a1;
}

.leaderboard-menu {
  width: 100%;
  font-size: 24px;
  display: grid;
  grid-template-columns: 50% 50%;
  border-bottom: 3px solid #fc03a1;
  box-shadow: 0 0 15px -1px #fc03a1,
    0 0 12px -1px #fc03a1 inset;
}

.leaderboard-table, .stats-table {
  width: 100%;
}

.table-title, .stats-title {
  color: #ffe600;
  font-size: 18px;
  text-shadow: 0 0 15px #ffe600;
}

.lb-player, .lb-rank, .lb-points, .lb-wins, .lb-losses {
  width: 20%;
}

.stats-content {
  width: 100%;
  /* padding-top: 0px; */
}

.stats-points, .stats-wins, .stats-losses, .stats-winloss {
  width: 25%;
}

.lb-player, .stats-points {
  padding-top: 10px;
}

.board-select {
  padding-top: 5px;
  color: #ffe600;
  text-shadow: 0 0 15px #ffe600;
  cursor: pointer;
}

.board-select.active {
  text-shadow: 0 0 15px #ffe600;
  background-color: #fc03a1;
  color: #ffe600;
  box-shadow: 0 0 15px -1px #fc03a1,
    0 0 12px -1px #fc03a1 inset;
}

.stats-data {
  padding-bottom: 5px;
}

.login-prompt {
  padding: 20px 0px 5px 0px;
  font-size: 22px;
}