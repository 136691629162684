/* NavBar CSS */

.navbar {
  display: flex;
  justify-content: space-between;
  height: 45px;
  padding: 20px 20px 0px 20px;
  z-index: 1;
}

.nav{
  display: flex;
}

.nav-select {
  padding-top: 5px;
  padding-right: 40px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color: #ffe600;
  text-shadow: 0 0 15px #ffe600;
  font-family: 'Josefin Sans', sans-serif;
}

.nav-select:hover {
  color: #fc03a1;
  text-shadow: 0 0 15px #fc03a1;
  cursor: pointer;
}

.active {
  color: #fc03a1;
  fill: #fc03a1;
}

/* Signup & Login CSS */

.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  width: 400px;
  border-radius: 10px;
  border: 5px solid #fc03a1;
  opacity: .9;
}

.form-container-login, .form-container-signup{
  position: absolute;
  width: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 0px 30px 0px;
  opacity: .9;
  color: #fc03a1;
}

.input-field {
  font-weight: 200;
  height: 50px;
  font-size: 16px;
  width: 225px;
  padding-left: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 5px solid #fc03a1;
  background-color: #ffe600;
  color: black;
  transition: ease-in-out .4s;
  box-shadow: 0 0 15px -1px #fc03a1,
    0 0 12px -1px #fc03a1 inset;
}

.input-field::placeholder {
  color: #fc03a1;
}

.input-field:focus {
  transform:scale(1.05);
}

.submit-button, .demo-login-button {
  color: rgba(0, 0, 0, .5);
  height: 50px;
  font-size: 16px;
  width: 225px;
  padding-left: 0px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #fc03a1;
  transition: ease-in-out .4s;
}

.submit-button:hover, .demo-login-button:hover, .join-button:hover {
  transform: scale(1.05);
}

.alt-text {
  text-align: center;
  padding-top: 10px;
  font-size: 14px;
  color: #fc03a1;
}

.errors {
  font-size: 14px;
  color: #ffe600
}

.logout-button, .login-button {
  font-size: 12px;
  width: 80px;
  height: 30px;
  line-height: 30px;
  margin-top: -3px;
  font-weight: 300;
  background: rgba(0, 0, 0, .8);
}

.pointer {
  cursor: pointer;
}

.login-title {
  text-align: center;
  font-size: 20px;
  padding-bottom: 20px;
  color: #ffe600;
}

.home-button {
  height: 30px;
  margin-top: -7px;
  fill: #ffe600;
}

.home-button:hover {
  fill: #fc03a1;
}

.greeting {
  padding-top: 5px;
  padding-right: 20px;
}

.loggedin {
  display: flex;
}

