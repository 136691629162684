/* Play CSS */

.play-lobby {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.server {
  padding: 40px;
}

.play-button {
  font-size: 1.9em;
  width: 150px;
  padding-top: 15px;
  padding-bottom: 7px;
  font-weight: 300;
  background: rgba(0, 0, 0, .8);
  margin: 20px auto;
  transition: ease-in-out .2s;
}

.create-room {
  font-size: 1.3em;
  width: 150px;
  padding-top: 12px;
  padding-bottom: 10px;
  background: rgba(0, 0, 0, .8);
}

.content {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.move {
  display: flex;
  justify-content: space-evenly;
}

.team-button {
  display: block;
  height: 40px;
  width: 120px;
  margin: auto;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 2px solid #ffe600;
  background-color: rgba(0, 0, 0, 0.3);;
  /* color: #fc03a1; */
  font-size: 18px;
  text-align: center;
  padding-top: 9px;
  transition: ease-in-out .4s;
  cursor: pointer;
}

.team-button:hover {
  transform:scale(1.05);
}

.instructions {
  /* width: 650px; */
  /* display: grid;
  grid-template-columns: 70% 30%; */
  color: #ffe600;
  padding-top: 30px;
  padding-bottom: 30px;
}

.howto {
  margin: 10px 0 20px 0px;
  font-size: 16px;
}

.lobby-content {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* padding: 40px; */
}

.server-list {
  min-height: 60px;
  padding-bottom: 20px;
}

.room-column-headers {
  display: flex;
  color: #fc03a1;
  text-shadow: 0 0 15px #fc03a1;
  justify-content: space-between;
  line-height: 24px;
  width: 500px;
}

.room-teams {
  display: flex;
  margin: 30px;
  justify-content: space-between;
  line-height: 24px;
  min-height: 300px;
  border: 4px solid #ffe600;
  border-radius: 14px;
  /* padding-top: 10px; */
}

.team-player{
  padding-top: 10px;
  flex-basis: 100%;
  color: #ffe600;
  text-shadow: 0 0 15px #ffe600;
}
.room-team div {
  flex-basis: 100%;
}

.room-team {
  flex-basis: 100%;
  padding-top: 20px;
}

.room-hover {
  color: yellow;
}

.room-column-headers div {
  padding: 0px 50px;
  flex-basis: 100%;
}

.red {
  padding: 10px;
  border-radius: 10px 0 0 10px;
  box-sizing: border-box;
  background-color: #ed5d47;
}

.blue {
  padding: 10px;
  border-radius: 0 10px 10px 0;
  box-sizing: border-box;
  background-color: #599dcf;
}

.neutral {
  background-color: grey;
  padding: 10px;
  box-sizing: border-box;
}